<script lang="ts" setup>
import type { strapiMedia } from '~/interfaces/strapi.interface'

withDefaults(
  defineProps<{
    image: strapiMedia
    align?: string
    transform?: string
  }>(),
  {
    transform: '',
    align: 'start',
  }
)

defineOptions({
  inheritAttrs: false,
})

const config = useRuntimeConfig()

const wealthTwicLink = ref('')

if (config.public.env === 'development' || config.public.env === 'staging') {
  wealthTwicLink.value = 'wealthLocal/'
} else if (config.public.env === 'preprod') {
  wealthTwicLink.value = 'wealthPreprod/'
} else {
  wealthTwicLink.value = ''
}
</script>

<template>
  <div
    v-if="image && image.data && image.data.attributes.caption"
    class="flex"
    :class="`justify-${align}`"
  >
    <div class="flex w-full flex-col">
      <img
        v-if="image && image.data"
        loading="lazy"
        v-bind="$attrs"
        class="block max-w-full"
        :alt="image.data.attributes.alternativeText"
        :data-twic-src="`media:/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}`"
        :data-twic-transform="`${transform}`"
        :src="`https://wealtheurazeo.twic.pics/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/output=preview`"
      />

      <span
        v-if="image && image.data && image.data.attributes.caption"
        class="text-right text-p4 text-grey-200"
      >
        {{ image.data.attributes.caption }}
      </span>
    </div>
  </div>
  <img
    v-else-if="image && image.data"
    v-bind="$attrs"
    loading="lazy"
    class="block max-w-full"
    :alt="image.data.attributes.alternativeText"
    :data-twic-src="`media:/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}`"
    :data-twic-transform="`${transform}`"
    :src="`https://wealtheurazeo.twic.pics/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/output=preview`"
  />
</template>
